import React, {useCallback, useMemo} from "react"
import Pill from "./Pill"
import {useSearchContext} from "./SearchContextProvider"
import {FacetFilterType} from "../utils/model";

export const parseName = (name: string) => name.indexOf("#") > -1 ? name.substring(name.lastIndexOf("#") + 1) : name


function useFacetValue(name: string, value: string) {
  const {searchFilters: {facets}, actions: {removeFilter, addFilter}} = useSearchContext();
  const isFacetSelected = useMemo(() => facets[name]?.includes(value) ?? false, [facets, name, value])
  const toogleFilter = useCallback(() => {
      if (isFacetSelected) {
        removeFilter(name, value)
      } else {
        addFilter(name, value)
      }
    }
    , [name, value, removeFilter, addFilter, facets, isFacetSelected])
  return {toogleFilter, isFacetSelected};
}

export const FacetValue = ({name, value, showValue, type}: {name: string, value: string, showValue: (value, count) => boolean, type: FacetFilterType}) => {
  const {searchResult} = useSearchContext();
  const {isFacetSelected, toogleFilter} = useFacetValue(name, value);

  // params value , distribution
  if (!showValue(value, searchResult.facetsDistribution?.[name]?.[value])){
    return null;
  }
  return    type === "pill" ?
      (
        <li className="font-sans cursor-pointer" onClick={toogleFilter}>
          <Pill name={`${parseName(value)} | ${searchResult.facetsDistribution?.[name]?.[value] ?? 0}`} selected={isFacetSelected}/>
        </li>
      )
      :
      (
        <li className="font-sans">
          <label className="text-sm truncate items-center flex cursor-pointer">
            <input
              type="checkbox"
              checked={isFacetSelected}
              onChange={toogleFilter}
              className="mr-2 cursor-pointer"
            />
            {parseName(value)}
          </label>
        </li>
      )
}

export default FacetValue

