import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {Organization} from "../utils/model"
import OrgFeatures from "./OrgFeatures"

const defaultProps = {
    linkText: `View the OrgLine`,
}

type OrgLineProps = { organization: Organization } & typeof defaultProps

const OrgLine = ({organization}: OrgLineProps) => {

    const data = useStaticQuery(graphql`
    query OrgLineQuery {
        placeholder: file(relativePath: { eq: "placeholder.png" }) {
            publicURL
        }
    }
    `)

    return (
        <Link
            to={organization.slug}
            className="bg-white rounded-md border-white shadow-md p-4 flex items-start flex-col mb-5">

            <h2 className="font-bold text-primary text-xl">{organization.name}</h2>
            <OrgFeatures
                organization={organization}
                className="text-shift text-xs justify-start space-x-3"/>
            <div
                className="font-serif text-base excerpt overflow-hidden flex-1 my-3"
                dangerouslySetInnerHTML={{
                    __html: organization.desc
                }}
            />
            {organization.tags.length >0 && <div className="flex content-start flex-wrap text-primary text-11px my-1">
                {organization.tags.map(t => (
                    <div className="capitalize-first-letter inline-block rounded border border-gray-300 px-2 py-0 mt-1 mr-1">{t}</div>

                ))}
            </div>}
        </Link>
    )
}


export default OrgLine

OrgLine.defaultProps = defaultProps
