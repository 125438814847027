import React, {useCallback, useEffect, useRef, useState} from "react";
import {NamedEntity} from "../utils/model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Boldify} from "./Boldify";
import {nameEntitiesFuseOptions, useFuzzyFilteredEntities} from "../utils/useFuse";
import classnames from "classnames"
import {useClickAwayListener} from "../utils/custom-hooks";


type BaseSelectProps = {
    options: NamedEntity[],
    onChange: (value: string[]) => void,
    description?: string,
    searchValuePlaceholder: string,
    value: string[]
}

export function changeEventToValueEvent<T>(changeHandler: (value: T) => any) {
    return (event: React.ChangeEvent<{ value: T }>) => changeHandler(event.target.value)
}

const BaseSelect = ({options, onChange, description = "Selectionner", searchValuePlaceholder, value}: BaseSelectProps) => {
    const wrapperRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => setSearchValue(""),[isOpen])
    const toggleIsOpen = useCallback(() => {
        setIsOpen(prevState => !prevState)
    }, []);
    const closeOptions = useCallback(() => {
        setIsOpen(false)
    }, []);
    useClickAwayListener(wrapperRef, closeOptions)
    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }, []);
    const fuzzyFilteredOptions = useFuzzyFilteredEntities(nameEntitiesFuseOptions, options, searchValue);
    const removeOption = useCallback((optionId: string) => {
        onChange(value.filter(valueUnit => valueUnit !== optionId))
    }, [onChange, value]);
    const addOption = useCallback((optionId: string) => {
        onChange([...value, optionId])
    }, [onChange, value]);
    const handleOptionClicked = useCallback((optionId: string) => {
        const isOptionSelected = value.includes(optionId)
        if (isOptionSelected) {
            removeOption(optionId)
            return;
        }
        addOption(optionId)
    }, [addOption, removeOption, value]);


    return <div
        ref={wrapperRef}
        className="relative inline-block text-left w-full">
        <div>
    <span className="rounded-md shadow-sm">
      <button
          onClick={toggleIsOpen}
          type="button"
          className="inline-flex  w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
          id="options-menu" aria-haspopup="true" aria-expanded="true">
          <span className="flex-1 text-left">{description}</span>
          <svg className={classnames("-mr-1 ml-2 h-5 w-5", {"transform rotate-180": isOpen})} viewBox="0 0 20 20"
               fill="currentColor">
              <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"/>
        </svg>
      </button>
    </span>
        </div>
        {
            isOpen && <div
              className="fixed top-0 left-0 right-0 z-10 bottom-0 lg:bottom-auto lg:absolute flex bg-white flex-col lg:-top-0.5 w-full lg:rounded-md shadow-lg lg:transform lg:-translate-y-full
            lg:rounded-md shadow-xs lg:max-h-half overflow-y-auto text-black
            ">
              <form className="flex items-center relative text-primary" onSubmit={handleSubmit}>
                <input
                  value={searchValue}
                  className="focus:outline-none text-xs border border-gray-300 bg-white placeholder-gray-500 lg:rounded py-2 pr-10 pl-4 block w-full appearance-none leading-normal"
                  onChange={changeEventToValueEvent(setSearchValue)}
                  placeholder={searchValuePlaceholder}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-4 absolute right-0 text-secondary"
                />

              </form>
              <div className="flex-1  overflow-y-auto">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {
                        fuzzyFilteredOptions.map(option => {
                            const isOptionSelected = value.includes(option.id);
                            return <div
                                onClick={() => handleOptionClicked(option.id)}
                                className={classnames({"bg-gray-100 text-gray-900, hover:bg-gray-200": isOptionSelected}, "flex cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900")}
                                key={option.id}
                                role="menuitem">
                                <div className="flex-1">
                                    <Boldify searchValue={searchValue} value={option.name}/>
                                </div>
                                {isOptionSelected && <FontAwesomeIcon
                                  className="cursor-pointer mx-1"
                                  size="lg"
                                  icon={faTimes}
                                />}
                            </div>;
                        })
                    }
                </div>
              </div>
               <button
                  onClick={toggleIsOpen}
                  type="button"
                  className="lg:hidden leading-4 inline-flex  w-full border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                  id="options-menu" aria-haspopup="true" aria-expanded="true">
                  <span className="w-full text-center uppercase">Validate</span>
                </button>
            </div>
        }
    </div>
}

export {BaseSelect};
