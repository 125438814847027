import escapeRegexp from "escape-string-regexp"
import React from "react";

export function Boldify<T>({
                               searchValue,
                               value
                           }: {
    searchValue: string,
    value: string
}) {
    if (searchValue === "") {
        return <span>{value}</span>
    }
    const searchValueRegexp = new RegExp(escapeRegexp(searchValue), "gi");
    return <span
        dangerouslySetInnerHTML={{__html: value.replace(searchValueRegexp, (substring => `<span style="font-weight:bold;text-decoration: underline">${substring}</span>`))}}></span>

}
