import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Search from "../components/Search"
import {SearchContextProvider} from "../components/SearchContextProvider";

function SearchPage(props) {
  useEffect(() => {
    document.body.className = "page-search"
  }, [])
  
  
  const url = new URL(props.location.href ?? "https://shiftyourjob.org/search/")
  // SEO FILTERS
  let seoTitle, googlebot = ''
  let gtmValue, gtmKey = 'plo-'
  if (url.searchParams.toString().length > 0) {
    googlebot = "noindex, nofollow";
    seoTitle = "les organisations "
    for (const [key, value] of url.searchParams) {
      gtmKey += `${key}-`
      if (value.indexOf("#") !== -1) {
        seoTitle += `${key} : ${value.split("#")[1]} - `
        gtmValue += `${value.split("#")[1]}-`
      } else {
        seoTitle += `${key} : ${value} - `
        gtmValue += `${value} - `
      }
      if (key === 'query') {
        gtmValue = `search: ${value}}`
        gtmKey = 'search'
      }
    }
  } else {
    googlebot = "index, follow";
    seoTitle =
      "Les employeurs pour la transition carbone & le développement durable "
    gtmValue = 'plo'
    gtmKey = 'plo'
  }
  
  
  return (
    <SearchContextProvider>
      <Layout contentClassName="bg-gray-100 ">
        <SEO
          meta={[{name: "googlebot", content: `${googlebot}`}]}
          title={seoTitle}
          description="Explorez la liste des entreprises et organisations qui oeuvrent pour la transition carbone et un monde plus durable"
          gtmPageType={`${gtmKey}`}
          gtmPageName={`${gtmValue}`}
        />
        <Search/>
      </Layout>
    </SearchContextProvider>
  )
}

export default SearchPage
