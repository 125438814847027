import {useMemo} from "react";


export function usePaginatedEntitieData(totalEntities: number, zeroBasedTablePageNumber: number, tablePageSize: number) {
    const numberOfPages = useMemo(() => Math.ceil(totalEntities / tablePageSize), [tablePageSize, totalEntities]);
    const hasMoreEntities = useMemo(() => {
        return zeroBasedTablePageNumber + 1 < numberOfPages
    }, [numberOfPages, zeroBasedTablePageNumber]);
    return {
        numberOfPages,
        hasMoreEntities,
    };
}
