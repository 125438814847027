import React, {useCallback, useEffect, useMemo, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons"
import classnames from "classnames"
import {FacetDistribution, FacetFilterType, NamedEntity} from "../utils/model"
import FacetValue from "./FacetValue"
import {BaseSelect} from "./BaseSelect";
import {useSearchContext} from "./SearchContextProvider";
import {FacetValueSummary} from "./FacetValueSummary";
import {sortBy} from "../utils/commonUtils";

type FacetFilterProps = {
    title: string
    facet: FacetDistribution
    expand: boolean
    show: boolean
    type: FacetFilterType
    showValue: (value: string, count: number) => boolean,
    options?: NamedEntity[],
    displaySummary?: boolean,
    sortFn?: (a: NamedEntity, b: NamedEntity) => number,
}

const defaultSort = (a: NamedEntity, b: NamedEntity) => {

    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;

}

const emptyOptions: NamedEntity[] = []
const FacetFilter = ({title, facet, expand, show, type, showValue, options = emptyOptions, displaySummary = false, sortFn}: FacetFilterProps) => {
    const [expandMenu, setExpandMenu] = useState(expand)
    const sortedOptions = useMemo(() => [
        ...options
    ].sort(sortBy("name")), [options]);
    useEffect(() => {
        setExpandMenu(expand);
    }, [expand]);
    const toggleExpandMenu = useCallback(() => {
        setExpandMenu(prevState => !prevState)
    }, [])
    const {
        searchFilters: {facets},
        actions: {replaceFilter}
    } = useSearchContext();

    const handleFacetFilterChange = useCallback((value: string[]) => {
        replaceFilter(facet.name, value)
    }, [facets, facet.name, replaceFilter]);

    const selectedFacets = useMemo(() =>facets[facet.name] ?? [], [facets, facet.name]);

    const optionsComponent = useMemo(() => {
        if (!expandMenu) {
            return null;
        }
        switch (type) {
            case "checkbox":
            case "pill":
                return <ul>
                    {
                        facet.distribution
                            .sort(sortFn??defaultSort)
                            .map(d => (
                                <FacetValue key={d.id} name={facet.name} value={d.name} showValue={showValue}
                                            type={type}/>
                            ))
                    }
                </ul>
            case "select":
                return <BaseSelect
                    options={sortedOptions}
                    onChange={handleFacetFilterChange}
                    searchValuePlaceholder={title}
                    value={selectedFacets}
                />
        }
    }, [expandMenu, type, sortedOptions, title, facet, facet.distribution, facet.name, facets, handleFacetFilterChange]);


    return (
        <div className={classnames(
            "leading-9 border-t border-gray-600 px-4 py-2",
            {hidden: !show}
        )}>
            <div className="flex flex-grow mb-2">
                <h3 className="text-sm text-base font-bold text-secondary">
                    {title}
                </h3>
                <div className="flex flex-grow flex-row-reverse">
                    <button
                        onClick={toggleExpandMenu}
                        className="flex-shrink-0"
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            size="xs"
                            icon={expandMenu ? faMinus : faPlus}
                            className="text-secondary self-center"
                        />
                    </button>
                </div>
            </div>
            {optionsComponent}
            {displaySummary &&  <div className=" leading-normal pt-2 flex flex-wrap">
                {selectedFacets.map(selectedFacet => <FacetValueSummary key={selectedFacet} facetName={facet.name} facetValue={selectedFacet}/>)}
            </div>}
        </div>
    )
}

export default FacetFilter

